:root
{
  --theme-blue: skyblue;
}
/* *
{
  font-size: 0.9em;
} */
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root
{
  --theme-red: #ce4437;
  --theme--dark-blue: #011b2d;
  --theme-light-blue: #011f35;
  --theme-dark-blue-transparent: #011f35d2;
  --theme-light-grey: #ededed;
  --theme-dark-grey: #6a6969;
  --theme-medium-grey: #b8b7b7;
  --theme-darkest-grey: #212529;
  --theme-footer-grey: #141414;
  --theme-white: white;
}
::selection {
  background-color: var(--theme-red); /* Background color of selected text */
  color: var(--theme-white); /* Text color of selected text */
}

/* Webkit browsers (Safari, Chrome) also support a prefixed version */
::-webkit-selection {
  background-color: var(--theme-red); /* Background color of selected text */
  color: var(--theme-white); /* Text color of selected text */
}
.ls-small{
  letter-spacing: 0.7px;
}
.ls-medium{
  letter-spacing: 1px;
}
.ls-large{
  letter-spacing: 1.5px;
}
.ls-larger{
  letter-spacing: 2px;
}

.bg-theme-red
{
  background-color: var(--theme-red);
}
.bg-theme-light-blue
{
  background-color: var(--theme-light-blue);
}
.bg-theme-dark-blue
{
  background-color: var(--theme--dark-blue);
}
.bg-theme-light-grey
{
  background-color: var(--theme-light-grey);
}
.bg-theme-dark-grey
{
  background-color: var(--theme-dark-grey);
}
.bg-theme-darkest-grey
{
  background-color: var(--theme-darkest-grey);
}
.bg-theme-footer-grey
{
  background-color: var(--theme-footer-grey);
}
.bg-theme-dark-blue-transparent
{
  background-color: var(--theme--dark-blue);
  /* background-color: var(--theme-dark-blue-transparent); */
}
.color-theme-red
{
  color: var(--theme-red);
}
.color-theme-light-grey
{
  color: var(--theme-light-grey);
}
.color-theme-dark-blue
{
  color: var(--theme--dark-blue);
}
.color-theme-medium-grey
{
  color: var(--theme-medium-grey);
}
.color-theme-dark-grey
{
  color: var(--theme-dark-grey);
}
.color-theme-darkest-grey
{
  color: var(--theme-darkest-grey);
}
*
{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.font-roboto
{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.font-mont
{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.myborder
{
  border: 5px solid red;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.display-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.display-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex-align-items-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.display-flex-align-items-top-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.display-flex-align-items-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.display-flex-align-items-top-between
{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.fs-smallest-css {
  font-size: 0.4em;
}
.fs-smaller-css {
  font-size: 0.6em;
}
.fs-medium-small-css
{
  font-size: 0.75em;
}
.fs-slight-small-css {
  font-size: 0.9em;
}
.fs-small-css {
  font-size: 0.6em;
}
.fs-medium-css {
  font-size: 1.2em;
}
.fs-slight-large-css
{
  font-size: 1.3em;
}
.fs-large-css {
  font-size: 1.8em;
}
.fs-larger-css {
  font-size: 3em;
}
.fs-largest-css {
  font-size: 4em;
}
.fs-huge-css
{
  font-size: 7em;
}
.input-field
{
  color: var(--theme-dark-grey);
  font-weight: 400;
  background-color: var(--theme-white);
  outline: none;
  padding: 15px 25px;
  font-size: 0.9em;
  border: 0.5px solid var(--theme-medium-grey);
  transition: all 0.6s;
}
.input-field:disabled
{
  background-color: var(--theme-medium-grey);
}
.input-field:focus{
  border: 0.5px solid var(--theme-red);
}
.input-field.wrong-input
{
  outline: 1px solid red;
  color: red;
  box-shadow: 2px 2px 10px red;
}
.input-field-blue.wrong-input
{
  outline: 1px solid red;
  color: red;
  box-shadow: 2px 2px 10px red;
}
.btn-universal-transparent-bg
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 15px 25px;
  outline: none;
  border: 1px solid var(--theme-white);
  transition: all 0.6s;
  border-radius: 2%;
  letter-spacing: 1.6px;
  font-weight: 800;
}
.btn-universal-transparent-bg:hover, .btn-universal-transparent-bg-focus
{
  color: var(--theme-darkest-grey);
  background-color: var(--theme-white);
}
.btn-universal-transparent-bg-blue
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 8px 25px;
  outline: none;
  border: 1px solid var(--theme-white);
  transition: all 0.6s;
  border-radius: 2%;
  letter-spacing: 1.6px;
  font-weight: 800;
}
.btn-universal-transparent-bg-blue:hover, .btn-universal-transparent-bg-blue:focus
{
  color: var(--theme-darkest-grey);
  background-color: var(--theme-white);
}
.btn-universal-transparent-bg-blue:disabled
{
  background-color: transparent;
  border: 1px solid var(--theme-dark-grey);
  color: var(--theme-dark-grey);
}
.btn-universal
{
  color: var(--theme-white);
  background-color: transparent;
  padding: 15px 25px;
  outline: none;
  border-bottom: 1px solid var(--theme-dark-grey);
  border-top: none;
  border-left: none;
  border-right: none;
  transition: all 0.6s;
  border-radius: 2%;
}
.btn-universal:hover , .btn-universal:focus
{
  color: var(--theme-darkest-grey);
  background-color: var(--theme-white);
}
.btn-universal-mod
{
  color: var(--theme-medium-grey);
  background-color: transparent;
  padding: 5px 10px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.6s;
  border-radius: 2%;
}
.btn-universal-mod:hover , .btn-universal-mod:focus
{
  color: var(--theme-white);
  cursor: pointer;
  /* background-color: var(--theme-white); */
}
.btn-universal-converse
{
  color: var(--theme-white);
  background-color: var(--theme--dark-blue);
  padding: 15px 25px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.6s;
  border-radius: 2%;
}
.btn-universal-converse:hover , .btn-universal-converse:focus
{
  color: var(--theme--dark-blue);
  background-color: var(--theme-white);
  
  border: 1px solid var(--theme--dark-blue);
}
.btn-universal-converse:disabled
{
  opacity: 0.6;
  background-color: var(--theme--dark-blue);
  color: var(--theme-white);
}
.navbar
{
  height: 100vh;
  background-color: var(--theme--dark-blue);
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transform: translateX(-100%);
  transition: all 0.6s;
  z-index: 999;
}
.navbar.show{
  transform: translateX(0%);
}
td, th
{
  width: 20rem;
  padding: 10px 20px;
  text-align: center;
}
th
{
  background-color: var(--theme--dark-blue);
  font-weight: 700;
  color: var(--theme-light-grey);
}
tr
{
  
  outline: 0.5px solid #f3f3f3;
}
td
{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.width-10vw
{
  width: 12rem;
}
tbody, thead
{
  padding: 0px !important;
  margin: 0px !important
}
table
{
  border-spacing: 0px !important;
  /* overflow-x: scroll; */
}
.overflow-hidden
{
  overflow-x: hidden;
}
.overflow-x-scroll
{
  overflow: scroll;
  max-height: auto; 
  min-height: 50vh;
  /* change this for changing height constraints of table */
}
.position-sticky-left
{
  position: sticky;
  left: 0px;
  width: auto;
  z-index: 99;
  border-right: 1px solid var(--theme--dark-blue);
}
.month-row
{
  background-color: var(--theme-light-grey);
  font-weight: 400;
}
.popup-container 
{
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  background-color: var(--theme-dark-blue-transparent);
}
.border-bottom-grey
{
  border-bottom: 1px solid var(--theme-medium-grey);
}
.emiStructureContainer
{
  height: 100vh;
  width: 100vw;
  background-color: var(--theme-dark-blue-transparent);
  position:  fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: scroll;

}
.emiStructureBox
{
  background-color: var(--theme-white);
}
.login-box
{
  border: 0.5px solid var(--theme-medium-grey);
}
.btn-link
{
  background-color: transparent;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 0.8em;
  text-decoration: underline;
  text-underline-offset: 5px;
  color: var(--theme--dark-blue);
}